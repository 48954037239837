import react from "react";

export default function About() {
  const heroImage =
    "https://dm2306files.storage.live.com/y4m0vGTa2s2-u3GbMNKMLa97AtyLZw340n3VSm6_UOLt-G6OP9iqbGYzKlrUBgGFemvvJ263ApGv6S59mA2ODlNqpeS27Flqp9kfNtPFvYonBJgltCzaBKybrh1VdQCDwNmc4m3pFIHw3aasUMuYQscWxX00DIhrLEHDeUYg0tudbU8OeJQIZPUFxDcMPU01Eqi?width=1024&height=641&cropmode=none";
  return (
    <div
      id="About"
      className="mt-20 md:mx-16 mx-5 font-helvLight flex flex-col"
    >
      <h1 className="py-8 text-4xl md:text-left text-center w-full">
        About Us
      </h1>
      <div
        className="bg-gray-300 bg-cover bg-no-repeat bg-center  w-auto h-60vh tracking-wider"
        style={{ backgroundImage: `url('${heroImage}')` }}
      />
      <p className="mt-8 ">
        Mirius Interni LLP was founded in April 2016. It is a brainchild of 4
        individuals from different backgrounds who knew nothing about design and
        architecture, the mere passion towards design and the force to try
        something out of the box lead them into forming the company.
        <br />
        Mirius Interni started off with reinventing Kitchenscapes with an
        evolved sensibility and an aesthetic flair. Now Mirius Interni offers a
        gamut of Classic, Modern and Contemporary Modular Kitchens, Wardrobes
        and Living Spaces. <br />
        We also assist renowned Interior Designers and Architects with Turnkey
        Interior Works (if required). We have a plethora of choices with
        features, surprising and refreshing finishes and materials that cater to
        customized needs of the Buyer.
      </p>
    </div>
  );
}
