import React from "react";
import { BsArrowRight } from "react-icons/bs";
// images
import Connect from "../images/icons/connect.png";
import Meet from "../images/icons/meet.png";
import Design from "../images/icons/design.png";
import Quote from "../images/icons/quote.png";
import Build from "../images/icons/build.png";
import Install from "../images/icons/install.png";
export default function AboutProcess() {
  const process = [
    {
      title: "Connect",
      icon: Connect,
    },
    {
      title: "Meet & Discuss",
      icon: Meet,
    },
    {
      title: "Design",
      icon: Design,
    },
    {
      title: "Quote",
      icon: Quote,
    },
    {
      title: "Build",
      icon: Build,
    },
    {
      title: "Install",
      icon: Install,
    },
  ];
  return (
    <div className="px-5 md:px-16">
      <div class="text-2xl font-helvThin w-full ">Our Process</div>
      <div class="flex  flex-wrap justify-between my-8 items-center">
        {process.map((item, index) => (
          <>
            <div class="flex-col flex md:w-auto w-1/3 justify-center items-center">
              <img
                src={item.icon}
                className=" h-20 md:h-24 w-20 md:w-24"
                alt=""
              />

              <div class="text-sm md:text-xl font-helvThin my-2">
                {item.title}
              </div>
            </div>
            {index + 1 !== process.length && (
              <BsArrowRight className="text-3xl text-mir-darkgrey mx-2 mb-10 hidden md:block" />
            )}
          </>
        ))}
      </div>
    </div>
  );
}
