import React, { useState, useEffect } from "react";
import axios from "axios";
import API from "../components/services/API";
export default function Caretip() {
  const [careTips, setCareTips] = useState();
  const [activeTipIndex, setActiveTipIndex] = useState();
  // flag to detect if user clicks on the pagination button
  const [clickedFlag, setClickedFlag] = useState(false);
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (careTips !== undefined && !clickedFlag) {
      setTimeout(() => {
        if (activeTipIndex === careTips.length - 1) {
          setActiveTipIndex(0);
        } else {
          setActiveTipIndex(activeTipIndex + 1);
        }
      }, 4000);
    }
  }, [activeTipIndex]);

  const getData = async () => {
    const { data: careTips } = await axios.get(API.careTips);
    setCareTips(careTips);
    setActiveTipIndex(0);
  };
  return (
    <div>
      {careTips && activeTipIndex !== undefined && (
        <div className="w-full md:h-60vh flex flex-col md:flex-row  my-10">
          {/* tip image carousel */}
          <div
            className="w-full md:w-3/5  bg-gray-100 relative h-30vh md:h-5/6 flex justify-center bg-cover bg-center bg-no-repeat "
            style={{
              backgroundImage: `url(${careTips[activeTipIndex].image})`,
            }}
          >
            <div className="absolute -bottom-8  flex w-full justify-center">
              {careTips.map((tip, index) => (
                <>
                  <button
                    onClick={() => {
                      setClickedFlag(true);
                      setActiveTipIndex(index);
                    }}
                    key={index}
                    className={` cursor-pointer rounded-full h-3 w-3  border-1 border-gray-700  hover:bg-gray-700 mx-2 ${
                      activeTipIndex === index ? "bg-gray-700" : "bg-white"
                    } `}
                  ></button>
                </>
              ))}
            </div>
          </div>
          {/* tip content section */}
          <div className="w-full md:w-2/5 md:h-5/6 flex flex-col justify-center  items-center  p-10 mt-5 md:mt-0">
            <div className="text-4xl font-helvLight font-bold mb-10 uppercase">
              Care
            </div>
            <div className="bg-black w-full " style={{ height: "1px" }} />
            <div className="font-helvThin font-bold text-justify mt-10">
              {careTips[activeTipIndex].tip}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
