import React from "react";
import $ from "jquery";
const LOGO = "/images/Logo2.png";

export default function Navber() {
  const links = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Products",
      link: "/products",
    },
    {
      name: "About",
      link: "/about",
    },
    {
      name: "Contact",
      link: "/contact",
    },
  ];

  // Functions for mobile navbar slider button
  const handleToggle = () => {
    $("#sideMenu").toggleClass("translate-x-full");
    $("#sideButton").toggleClass("active");
  };
  const resize = () => {
    $("#sideMenu").addClass("translate-x-full");
  };

  return (
    <div id="Nav">
      {/* Desktop Navbar */}
      <nav className="hidden h-20 fixed md:flex font-helvLight text-white bg-mir-darkgrey px-4 py-2 text-base justify-between min-w-full z-40">
        <a href="/"><img src={LOGO} alt="Mirius Logo" width="160px" /></a>
        <ul className="flex md:flex-row md:mx-10">
          {links.map((inputs, key) => (
            <li key={key} className="md:mx-6 my-5 uppercase hover:text-gray-300">
              <a href={inputs.link}>{inputs.name}</a>
            </li>
          ))}
        </ul>
      </nav>

      {/* Mobile Navbar */}
      <div className="md:hidden bg-mir-darkgrey text-white overflow-hidden p-3 top-0 fixed z-50 w-full flex justify-between items-center h-auto">
        <a href="/" className="ml-2">
          <img src={LOGO} alt="Mirius Logo" width="160px" />
        </a>
        <nav
          id="sideMenu"
          className="text-xl sideMenu fixed p-36 text-center bg-mir-darkgrey inset-y-0 right-0 transform transition items-center translate-x-full duration-500 ease-in-out uppercase min-h-screen w-full flex z-10 flex-col justify-around min-w-screen"
        >
          {links.map((value, key) => (
            <div key={key} className="block" onClick={resize}>
              <a href={value.link}>{value.name}</a>
            </div>
          ))}
        </nav>
        <button onClick={handleToggle} className="z-50 flex" id="sideButton">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="70"
            viewBox="0 0 200 200"
          >
            <g stroke-width="6.5" stroke-linecap="round">
              <path
                d="M72 82.286h28.75"
                fill="#009100"
                fill-rule="evenodd"
                stroke="#fff"
              />
              <path
                d="M100.75 103.714l72.482-.143c.043 39.398-32.284 71.434-72.16 71.434-39.878 0-72.204-32.036-72.204-71.554"
                fill="none"
                stroke="#ffffff"
              />
              <path
                d="M72 125.143h28.75"
                fill="#ffffff"
                fill-rule="evenodd"
                stroke="#fff"
              />
              <path
                d="M100.75 103.714l-71.908-.143c.026-39.638 32.352-71.674 72.23-71.674 39.876 0 72.203 32.036 72.203 71.554"
                fill="none"
                stroke="#fff"
              />
              <path
                d="M100.75 82.286h28.75"
                fill="#009100"
                fill-rule="evenodd"
                stroke="#fff"
              />
              <path
                d="M100.75 125.143h28.75"
                fill="#ff0"
                fill-rule="evenodd"
                stroke="#fff"
              />
            </g>
          </svg>
        </button>
      </div>
    </div>
  );
}
