import React, { useState, useEffect } from "react";
import axios from "axios";
import API from "./services/API";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory } from "react-router-dom";

export default function SpecificProduct(props) {
  const history = useHistory();

  const { category, theme } = props.match.params;
  const [product, setProduct] = useState();
  const [prevCat, setPrevCat] = useState();
  const [nextCat, setNextCat] = useState();
  const [carouselImgs, setCarouselImgs] = useState([]);
  const [carouselImage, setCarouselImage] = useState();

  // invalid product
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const { data: products } = await axios.get(API.productThemes);
    let pos = null;
    products.forEach((data, i) => {
      if (
        data.theme &&
        data.category.toLowerCase() === category.toLowerCase() &&
        data.theme.toLowerCase() == theme.toLowerCase() &&
        data.tagline &&
        data.thumbnailImage2
      ) {
        pos = i;
        console.log(data);
      }
    });

    if (pos == null) {
      setRedirect(true);
      history.push(`/products/${category}`);
      return;
    }

    // setPrevCat(products[(pos - 1 + products.length) % products.length]);
    // setNextCat(products[(pos + 1) % products.length]);
    setPrevCat(products[(pos - 1 + 16) % 16]);
    setNextCat(products[(pos + 1) % 16]);
    setProduct(products[pos]);

    const imageLength = products[pos].carouselImages.split(",").length;
    let activeSlide = 0;
    // increase activeSlide every 3 seconds
    setInterval(() => {
      activeSlide = (activeSlide + 1) % imageLength;
      setCarouselImage(products[pos].carouselImages.split(",")[activeSlide]);
    }, 3500);
  };
  // download function
  function download() {
    window.location = product.brochure;
    return false;
  }
  return (
    <>
      <div className="flex flex-col px-5 md:px-16 pt-32 md:pt-24 ">
        <div className="md:min-h-screen">
          {/* path */}
          <div className="flex my-3 md:my-4  font-helvRegular font-semibold tracking-widest text-base xs:text-xs ss:text-base">
            <a href="/products" className="mr-1">
              Products{" "}
            </a>
            <a href={`/products/${category}`} className="mx-1">
              {`> ${
                category[0].toUpperCase() + category.slice(1).toLowerCase()
              }`}{" "}
            </a>
            <div className="mx-1">
              {`> ${theme[0].toUpperCase() + theme.slice(1).toLowerCase()}`}
            </div>
          </div>
          {/* hero section */}
          <div className="flex flex-col   justify-center w-full text-justify md:w-5/6 ">
            <h1 className=" text-4xl font-helvLight ">
              {theme
                .split(" ")
                .map(
                  (word) =>
                    word[0].toUpperCase() + word.slice(1).toLowerCase() + " "
                )}
              {/* {theme[0].toUpperCase(0) + theme.slice(1).toLowerCase()} */}
            </h1>
          </div>
          {/* temp carousel, comment this when you have below one working */}
          <div className="h-50vh md:h-70vh mt-6 w-full">
            {product && (
              <div
                className="w-full h-full bg-cover bg-center  bg-no-repeat bg-white ease-linear "
                style={{
                  backgroundImage: `url('${
                    carouselImage
                      ? carouselImage
                      : product.carouselImages.split(",")[0]
                  }')`,
                }}
              />
            )}
          </div>
        </div>

        {/* second section */}
        <div className="flex flex-col-reverse md:flex-row justify-between  mb-4 md:mb-0 ">
          {product && (
            <div
              className="bg-gray-200 h-20vh md:h-50vh  bg-cover bg-center bg-no-repeat w-full md:w-1/3"
              style={{ backgroundImage: `url('${product.thumbnailImage2}')` }}
            />
          )}
          <div className="w-full md:w-2/3 flex-col my-3 md:my-0 md:pl-16  text-justify justify-between font-helvThin  hidden md:flex">
            {product &&
              product.description
                .split("\n")
                .map((content) => <div>{content}</div>)}
          </div>
          <div className="w-full md:w-2/3 flex-col my-5 md:my-0 md:pl-16 text-justify  justify-between font-helvThin   flex md:hidden ">
            {product && product.description.split("\n")[0]}
          </div>
        </div>
        {/* brochure */}
        {product && (
          <div className="w-full h-auto  bg-mir-medlightgrey flex flex-col md:flex-row justify-between px-4 md:px-20 py-4 md:py-6 my-5 md:my-10 items-center">
            <div className=" font-helvLight text-lg md:text-2xl text-center md:text-left   tracking-wider ">
              Take a look at what Mirius has to offer
            </div>
            <a
              href={product.brochure}
              // onclick={() => download()}
              // onClick={`location.href=${"product.brochure"}`}
              download={product.theme}
              className="p-2 md:p-5 font-helvThin   tracking-wider font-bold uppercase  bg-black  text-white hover:bg-gray-400 hover:border-gray-400 duration-300"
            >
              Download Brochure
            </a>
          </div>
        )}
        {/* color and finishes */}
        {product && product.finishes && (
          <div className="h-auto bg-gray-200 w-full p-4 md:p-20">
            <div className="text-3xl text-center md:text-left font-helvThin font-bold">
              Colours and finishes
            </div>
            <div className=" text-center md:text-left font-helvRegular font-bold tracking-wider uppercase my-8">
              glossy glass
            </div>

            <div className="flex flex-wrap  justify-center  md:justify-start ">
              {product.finishes.map((finish, index) => (
                <div
                  className={`flex-col  ${
                    index === 0 ? "md:ml-0" : "md:ml-4"
                  } md:mr-4 mx-4 my-4   `}
                >
                  <div className="w-24 h-24 bg-black "></div>
                  <div className=" text-center font-bold font-helvThin text-gray-500 text-xs">
                    {finish.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* prev and next category */}
        {prevCat && nextCat && (
          <div className="flex justify-between w-full mb-16  font-helvLight font-extrabold text-lg md:text-3xl tracking-wider ">
            {/* prev */}
            <a
              href={`/products/${prevCat.category}/${prevCat.theme}`}
              className="relative h-40 md:h-96  text-center bg-black w-5/12 md:w-2/5  flex justify-center items-center bg-cover bg-center bg-no-repeat  "
            >
              <div
                className="bg-cover bg-center bg-no-repeat h-full w-full z-10 opacity-50"
                style={{
                  backgroundImage: `url('${prevCat.thumbnailImage}')`,
                }}
              ></div>
              <div className="absolute h-full w-full top-0 right-0 z-20 flex items-center justify-center px-4 md:px-0 text-white flex-col">
                <div className="font-helvThin  md:text-3xl text-xl ">
                  {" "}
                  {prevCat.theme}
                </div>
                <div className="mt-2 text-xs md:text-base hidden md:block font-helvLight font-thin">
                  {prevCat.tagline}
                </div>
                <a
                  href={`/products/${prevCat.category}/${prevCat.theme}`}
                  className="underline font-helvLight font-thin text-xs  md:text-base  my-3"
                >
                  Discover
                </a>
              </div>
            </a>
            {/* next */}
            <a
              href={`/products/${nextCat.category}/${nextCat.theme}`}
              className="relative h-40 md:h-96  md:px-0 text-center bg-black w-5/12 md:w-2/5 flex justify-center items-center bg-cover bg-center bg-no-repeat "
            >
              <div
                className="bg-cover bg-center bg-no-repeat h-full w-full z-10 opacity-50"
                style={{
                  backgroundImage: `url('${nextCat.thumbnailImage}')`,
                }}
              ></div>
              <div className="absolute h-full w-full top-0 right-0 z-20 flex items-center justify-center px-4 md:px-0 text-white flex-col">
                <div className="font-helvThin md:text-3xl text-xl">
                  {" "}
                  {nextCat.theme}
                </div>
                <div className="mt-2   text-xs md:text-base hidden md:block font-helvLight font-thin">
                  {nextCat.tagline}
                </div>
                <a
                  href={`/products/${nextCat.category}/${nextCat.theme}`}
                  className="underline font-helvLight font-thin text-xs  md:text-base my-3"
                >
                  Discover
                </a>
              </div>
            </a>
          </div>
        )}
      </div>
    </>
  );
}
