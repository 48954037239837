// Dependencies
import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// Components
import Navbar from "./components/Navbar";
import Homepage from "./components/Homepage";
import HomeCounter from "./components/HomeCounter";
import Brands from "./components/Brands";
import Testimonials from "./components/Testimonials";
import ProductTheme from "./components/ProductTheme";
import ProductsPage from "./components/ProductsPage";
import Footer from "./components/Footer";
import BrandsHome from "./components/BrandsHome";
import SpecificProduct from "./components/SpecificProduct";
import About from "./components/About";
import AboutVision from "./components/AboutVision";
import AboutPeople from "./components/AboutPeople";
// import AboutAwards from "./components/AboutAwards";
import Contact from "./components/Contact";
import AboutBelieve from "./components/AboutBelieve";
import AboutProcess from "./components/AboutProcess";
import NotFoundPage from "./components/NotFoundPage";
export default function App() {
  return (
    <div className="overflow-x-hidden">
      <Navbar />
      <BrowserRouter>
        <Switch>
          <Route exact path="/about" component={AboutUs} />
          <Route exact path="/brands" component={Brands} />
          <Route exact path="/contact" component={Contact} />
          {/* <Route exact path="/brands/:brand" component={ProductTheme} />
          <Route exact path="/brands/:brand/:id" component={ProductTheme} /> */}
          <Route exact path="/products" component={ProductsPage} />
          <Route exact path="/products/:category" component={ProductTheme} />
          <Route
            path="/products/:category/:theme"
            component={SpecificProduct}
          />
          <Route path="/" exact component={Home} />
          <Route path="" component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

const Home = () => {
  return (
    <div id="Home">
      <Homepage />
      <HomeCounter />
      {/* <Testimonials /> */}
      <BrandsHome />
    </div>
  );
};

const AboutUs = () => {
  return (
    <div>
      <About />
      <AboutBelieve />
      <AboutProcess />
      {/* <AboutVision /> */}
      {/* <AboutPeople /> */}
    </div>
  );
};
