import React from "react";
import CheckIcon from "../images/icons/yellow-check.png";
export default function AboutBelieve() {
  const beliefs = [
    " Commitment to providing the best possible service for each and every kitchen, wardrobe, and living space in your home.",
    "Honesty to turning our projects into truly sustainable living spaces.",
    "Respect for the environment, as well as the entire production chain.",
    "To be original and increasingly competitive in technological and practical innovation.",
  ];

  return (
    <div className=" bg-mir-medlightgrey px-5 md:px-16 w-full  my-10 py-10">
      <div class="md:text-3xl text-2xl font-helvThin mb-4">
        What we believe in
      </div>
      {beliefs.map((belief, index) => (
        <div key={index} className=" font-helvThin flex items-center ">
          <img
            src={CheckIcon}
            alt="check"
            className="w-5 h-5 md:w-8 md:h-8 mr-2   md:mr-8 my-5"
          />
          <p className="  text-xs md:text-base   ">{belief}</p>
        </div>
      ))}
    </div>
  );
}
