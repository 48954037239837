import React from "react";

export default function NotFoundPage() {
  return (
    <div className="mt-20 h-50vh w-full flex flex-col justify-center items-center">
      <div className="text-6xl  font-helvBold">Error 404</div>
      <div className="text-2xl font-helvThin  my-3 font-bold ">
        Page Not Found... Go back to
        <span>
          {" "}
          <a className=" bg-black text-white px-2 py-1" href="/">
            Home
          </a>{" "}
        </span>{" "}
      </div>
    </div>
  );
}
