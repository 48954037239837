import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export default function Contact() {
  const notify = (tText) => toast(tText); // Toastify
  const HeroImage =
    "https://dm2306files.storage.live.com/y4m2rbZyr9W0pQy48EN5UHd2tr8KkmaUlfKwwvyBCP5PugLVSPw5J6FtVwtZ1KQCi79t0ySshEoAjGb87I_7hjAzG28NQhEkqk-ZJPPmBZzSjO57K86yhOUP-7quBA0WjWHPgj4EmiO49KpYmAvib4Jz4LwTVDbY9HP71G7q1CbSJLy96psZgEhhDcWspCNOVMb?width=1024&height=768&cropmode";
  //Form data
  const [borderColor] = "lightgray";
  const contactAPI = process.env.REACT_APP_API_END + "contacts";
  const [formMail, setFormMail] = useState({
    mail: "",
    error: "Enter a valid Phone Number or Email",
  });
  const [formPhone, setFormPhone] = useState({
    phno: "",
    error: "Enter a valid Phone Number or Email",
  });
  const [formDetails, setFormDetails] = useState({
    name: "",
    msg: "",
    error: {
      name: "Enter a valid Name",
      msg: "Enter a valid Message",
    },
  });

  //Functions to validate Email and Phone
  const validateEmail = RegExp(
    // eslint-disable-next-line
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  const validatePhone = RegExp(
    // eslint-disable-next-line
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i
  );

  //Contact form change and Submit Handlers
  let changeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;

    switch (nam) {
      case "name":
        setFormDetails((prevState) => ({
          ...prevState,
          error: {
            ...prevState.error,
            name: val.length < 2 ? "Enter a vaild Name" : "",
          },
        }));
        break;

      case "phno":
        //If Email is already entered, phone error is set to ""
        if (validateEmail.test(formMail.mail)) {
          setFormPhone((prevState) => ({
            ...prevState,
            error: "",
          }));
        } else {
          setFormPhone((prevState) => ({
            ...prevState,
            error: validatePhone.test(val)
              ? ""
              : "Enter a valid Phone Number or Email",
          }));
        }
        break;

      case "mail":
        // If Phone is already entered, mail error is set to ""
        if (validatePhone.test(formPhone.phno)) {
          setFormMail((prevState) => ({
            ...prevState,
            error: "",
          }));
        } else {
          setFormMail((prevState) => ({
            ...prevState,
            error: validateEmail.test(val)
              ? ""
              : "Enter a valid Phone Number or Email",
          }));
        }
        break;

      case "msg":
        setFormDetails((prevState) => ({
          ...prevState,
          error: {
            ...prevState.error,
            msg: val.length < 5 ? "Enter a valid message!" : "",
          },
        }));
        break;
      default:
        break;
    }
    if (nam === "mail") {
      setFormMail((prevState) => ({ ...prevState, [nam]: val }));
    } else if (nam === "phno") {
      setFormPhone((prevState) => ({ ...prevState, [nam]: val }));
    } else {
      setFormDetails((prevState) => ({ ...prevState, [nam]: val }));
    }
  };

  //Runs when submit button is clicked
  let handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formDetails);
    console.log(formMail);
    console.log(formPhone);

    const validateForm = (errors) => {
      let valid = true;
      Object.keys(errors).forEach((key) => {
        if (errors[key].length !== 0 && valid === true) {
          notify(
            <div className=" text-red-500 text-center">{errors[key]}</div>
          );
          valid = false;
        }
      });
      return valid;
    };

    const validateVars = (errors) => {
      let validV = false;
      if (!errors.error) {
        validV = true;
      }
      return validV;
    };

    if (validateForm(formDetails.error)) {
      if (validateVars(formPhone) || validateVars(formMail)) {
        const formData = {
          name: formDetails.name,
          mail: formMail.mail,
          phone: formPhone.phno,
          message: formDetails.msg,
        };

        // axios.post(contactAPI, formData, {
        //   headers: {
        //     Authorization: process.env.REACT_APP_API_KEY,
        //   },
        // });

        // Reset field placeholders
        document.getElementById("cForm").reset();
        document.getElementById("name").placeholder = "Your Name";
        document.getElementById("mail").placeholder = "Email";
        document.getElementById("phno").placeholder = "Phone";
        document.getElementById("msg").placeholder = "Your Message";

        //Resetting border colors
        document.getElementById("name").style.borderColor = "lightgray";
        document.getElementById("mail").style.borderColor = "lightgray";
        document.getElementById("phno").style.borderColor = "lightgray";
        document.getElementById("msg").style.borderColor = "lightgray";

        //Displaying Success Toast
        notify(
          <div className=" text-green-800 text-center">
            Thank you for your interest in Mirius!
          </div>
        );

        //Resetting Values to prevent multiple entries
        setFormDetails({
          name: "",
          msg: "",
          error: {
            name: "Enter a vaild Name",
            msg: "Enter a vaild Message",
          },
        });
        setFormMail({
          mail: "",
          error: "Enter a valid Phone Number or Email",
        });
        setFormPhone({
          phno: "",
          error: "Enter a valid Phone Number or Email",
        });
      }
    } else {
      //Resetting border colors
      document.getElementById("name").style.borderColor = "lightgray";
      document.getElementById("mail").style.borderColor = "lightgray";
      document.getElementById("phno").style.borderColor = "lightgray";
      document.getElementById("msg").style.borderColor = "lightgray";

      //Setting Color fields of invalid entries red
      if (formDetails.name.length < 2) {
        document.getElementById("name").style.borderColor = "red";
      }
      if (!validateEmail.test(formMail.mail)) {
        if (!validatePhone.test(formPhone.phno)) {
          document.getElementById("mail").style.borderColor = "red";
        }
      }
      if (!validatePhone.test(formPhone.phno)) {
        if (!validateEmail.test(formMail.mail)) {
          document.getElementById("phno").style.borderColor = "red";
        }
      }
      if (formDetails.msg.length < 5) {
        document.getElementById("msg").style.borderColor = "red";
      }
    }
  };

  return (
    <div className="h-full w-full">
      <div
        class="bg-gray-100 bg-center mt-20 bg-no-repeat bg-cover h-60vh w-full "
        style={{ backgroundImage: `url('${HeroImage}')` }}
      ></div>
      <div className="mt-10 md:mx-16 mx-5 mb-10">
        {window.innerWidth > 767 ? (
          <ToastContainer
            className="contactToast ml-12 font-montserrat text-center text-green-800"
            position="bottom-left"
            autoClose={4000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
          />
        ) : (
          <ToastContainer
            className="text-center font-montserrat font-medium text-green-800"
            position="bottom-center"
            autoClose={4000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
          />
        )}

        <div class="md:flex-row flex flex-col-reverse  h-auto w-full">
          <div class="md:w-1/2 w-full h-40vh md:h-auto mt-10 md:mt-0  bg-gray-500">
            <iframe
              title="maps"
              className="w-full h-full"
              src="https://maps.google.com/maps?width=50%25&amp;height=600&amp;hl=en&amp;q=Mirius%20interni+(Mirius%20Interni)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div class="md:w-1/2 w-full  md:pl-20 ">
            <div class=" w-full md:w-4/5">
              <h1 className="md:text-4xl mb-2 text-5xl">Contact Us</h1>
              <p className="md:text-lg md:mb-3 text-lg">
                Tell us about your project and let’s make it happen!
              </p>
              <div className="flex md:flex-row flex-col justify-between ">
                <div className="flex    w-full md:my-0 my-2   items-center  ">
                  <form
                    id="cForm"
                    className="flex flex-col w-full md:w-4/5 font-helvLight text-xl"
                  >
                    <input
                      placeholder="Your Name"
                      id="name"
                      name="name"
                      className="border-1 px-2 py-2 border-gray-400 rounded-md my-2"
                      type="text"
                      onChange={(event) => {
                        changeHandler(event);
                      }}
                    />

                    <input
                      type="email"
                      name="mail"
                      id="mail"
                      placeholder="Email"
                      onChange={(event) => {
                        changeHandler(event);
                      }}
                      className="border-1 px-2 py-2 border-gray-400 rounded-md my-2"
                    />

                    <input
                      type="tel"
                      name="phno"
                      id="phno"
                      placeholder="Phone"
                      onChange={(event) => {
                        changeHandler(event);
                      }}
                      className="border-1 px-2 py-2 border-gray-400 rounded-md my-2"
                    />

                    <textarea
                      type="text"
                      name="msg"
                      id="msg"
                      placeholder="Your Message"
                      onChange={(event) => {
                        changeHandler(event);
                      }}
                      className="border-1 px-2 py-2 border-gray-400 rounded-md my-2"
                    />
                    <input
                      className="w-32 bg-black md:mt-2 mt-2 py-2 text-white hover:bg-mir-darkgrey duration-200 cursor-pointer text-xl rounded-md"
                      type="submit"
                      value="Enquire"
                      onClick={(event) => {
                        handleSubmit(event);
                      }}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="my-5 flex flex-col min-h-full md:w-4/10 w-full items-center justify-between md:pl-8">
            <div className="bg-mir-medlightgrey w-full flex items-center flex-col py-2">
              <div className="md:text-xl text-2xl md:mb-0 mb-2 font-medium">
                Address
              </div>
              <div>
                <a
                  className="hover:text-mir-darkgrey md:text-base text-lg"
                  href="https://goo.gl/maps/FL19utau4oTvG4b59"
                  target="_blank"
                >
                  #770, 10th Main Road <br />
                  Jayanagar 4th Block <br />
                  Bangalore - 560011
                </a>
              </div>
            </div>
            <div className="bg-mir-medlightgrey w-full flex items-center flex-col py-2 my-2">
              <div className="md:text-xl text-2xl md:mb-0 mb-2 font-medium">
                Phone
              </div>
              <div>
                <a
                  className="hover:text-mir-darkgrey md:text-base text-lg"
                  href="tel:9886125540"
                >
                  9886125540
                </a>{" "}
                /{" "}
                <a
                  className="hover:text-mir-darkgrey md:text-base text-lg"
                  href="tel:9880998808"
                >
                  9880998808
                </a>
              </div>
              <div className="md:text-xl text-2xl md:mb-0 mb-2 mt-5 font-medium">
                Email
              </div>
              <div>
                <a
                  className="hover:text-mir-darkgrey md:text-base text-lg"
                  href="mailto:director@miriusinterni.com"
                >
                  director@miriusinterni.com
                </a>
              </div>
            </div>
            <div className="bg-mir-medlightgrey w-full flex items-center flex-col py-2">
              <div className="md:text-xl text-2xl md:mb-2 mb-2 font-medium">
                Social Media
              </div>
              <div className="flex flex-row">
                <a
                  className="m-1"
                  href="https://www.facebook.com/miriusinterni/"
                  target="_blank"
                >
                  <img
                    src="/images/facebook.png"
                    width="50px"
                    height="50px"
                    alt="Facebook Logo"
                  />
                </a>
                <a className="m-1" href="" target="_blank">
                  <img
                    src="/images/twitter.png"
                    width="50px"
                    height="50px"
                    alt="Facebook Logo"
                  />
                </a>
                <a className="m-1" href="" target="_blank">
                  <img
                    src="/images/insta.png"
                    width="50px"
                    height="50px"
                    alt="Facebook Logo"
                  />
                </a>
                <a className="m-1" href="" target="_blank">
                  <img
                    src="/images/youtube.png"
                    width="50px"
                    height="50px"
                    alt="Facebook Logo"
                  />
                </a>
              </div>
            </div>
          </div> */}
    </div>
  );
}
