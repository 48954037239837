import React from "react";
import Slider from "react-slick";
export default function BrandsHome() {
  const brands = [
    {
      name: "Stosa Cucine (Italian)",
      image: "/images/brands/logo-stosa.jpg",
    },
    {
      name: "Hafele",
      image: "/images/brands/Hafele.jpeg",
    },
    {
      name: "Blum",
      image: "/images/brands/blum.jpg",
    },
    {
      name: "Salice",
      image: "/images/brands/salice.jpg",
    },
    {
      name: "Siemens",
      image: "/images/brands/Logo_Siemens.jpg",
    },
    {
      name: "Vauth Sagel",
      image: "/images/brands/vauth-sagel.png",
    },
    {
      name: "Grass",
      image: "/images/brands/grass_green-logo.jpg",
    },
    {
      name: "Kessebohmer",
      image: "/images/brands/Kessebohmer.jpg",
    },
    {
      name: "Bosch",
      image: "/images/brands/bosch-appliance.png",
    },
    {
      name: "Elica",
      image: "/images/brands/elica-aria.png",
    },
    {
      name: "Miele",
      image: "/images/brands/Miele-Logo.jpg",
    },
    {
      name: "Liebherr",
      image: "/images/brands/liebherr.jpg",
    },
    {
      name: "Whirlpool",
      image: "/images/brands/whirlpool.png",
    },
    {
      name: "Kaff",
      image: "/images/brands/kaff.jpg",
    },
    {
      name: "OSG Water Tech Company",
      image: "/images/brands/OSG.png",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    slidesToShow: 2,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: false,
    rows: 1,
    slidesPerRow: 3,
  };
  return (
    <div className="w-full   md:h-50vh flex flex-col md:flex-row justify-between px-5 md:px-16 my-10">
      {/* brands carousel */}
      <div className="w-full md:w-3/5 h-96 md:h-9/10 flex  items-center justify-center overflow-hidden ">
        <Slider
          className="w-full  items-center justify-center overflow-hidden  "
          {...settings}
        >
          {brands.map((brand) => (
            <div className="w-1/2 md:w-1/3 h-32 flex justify-between items-center  ">
              <div
                className="  w-24  md:w-32  h-24 md:h-32   bg-contain bg-center bg-no-repeat"
                style={{ backgroundImage: `url('${brand.image}')` }}
              ></div>
            </div>
          ))}
        </Slider>
      </div>
      {/* content */}
      <div className="w-full md:w-2/5 h-1/2 md:h-full flex flex-col justify-between  py-16 items-end  bg-mir-medlightgrey pr-10 ">
        <div className=" font-helvLight text-4xl text-right mb-4 md:mb-8  ">
          Brands we use
        </div>
        <div
          className="bg-gray-800  w-4/5  mb-4 md:mb-8"
          style={{ height: "1px" }}
        />

        <div className="md:w-9/12 text-right md:text-base font-helvLight text-lg">
          Our collaboration with these prestigious brands help us in creating
          elegant solutions for our clients.
        </div>
      </div>
    </div>
  );
}
