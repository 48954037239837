import React, { useState, useEffect } from "react";
import API from "./services/API";
import axios from "axios";

export default function ProductsPage() {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const { data: products } = await axios.get(API.products);
    setProducts(products);
  };

  // product category card
  function productCategory(category, index) {
    return (
      <div
        className={` mb-10 w-full md:w-1/2  h-30vh md:h-40vh flex 
        ${index % 2 == 0 ? " md:pr-10" : "md:pl-10"}
        `}
      >
        <a
          href={`products/${category.name}`}
          className="h-full w-1/2 bg-cover bg-no-repeat bg-center"
          style={{
            backgroundImage: `url('${category.image}')`,
          }}
        />
        <div className="h-full w-1/2  bg-gray-200 flex flex-col justify-center text-left px-2.5 md:pl-10 md:pr-3">
          <div className=" md:text-3xl my-1 md:my-3  font-helvLight   ">
            {category.name}
          </div>
          <div className="  text-xs md:text-sm my-1 md:my-2 text-justify md:text-left font-helvLight ">
            {category.description.split("\n")[0]}
          </div>
          <a
            className="text-xs md:text-base my-1 md:my-0 flex uppercase font-helvLight max-w-max"
            href={`products/${category.name}`}
          >
            <div className="mr-3 text-gray-500 font-bold">______</div>
            <div>VIEW</div>
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col px-5 md:px-16 pt-32 md:pt-28 w-full   overflow-x-hidden  ">
      {/* header */}
      <div className="flex flex-col   justify-center md:w-5/6">
        <h1 className="text-4xl   font-helvLight uppercase  ">Products</h1>
        <p className=" my-7 text-sm md:text-base font-helvLight   ">
          Our products are designed with the style and functionality to make the
          time you spend in the kitchen more enjoyable.
          <br />
          From classic to modern collections and from kitchen to living area
          furniture, we interpret, design and adapt space to meet your needs and
          match your desires.
        </p>
      </div>
      {/* product categories */}
      <div className="flex flex-wrap  md:justify-between w-full h-full   ">
        {products &&
          products.map((product, index) => productCategory(product, index))}
      </div>
    </div>
  );
}
