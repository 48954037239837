import React, { useState, useRef } from "react";
import CountUp from "react-countup";
import useVisible from "./Hooks/useIsVisible";
import background from "../images/counterBg.jpg";

export default function HomeCounter() {
  // Constants for custom Hook
  const elemRef = useRef();
  const isVisible = useVisible(elemRef);

  return (
    <div className="relative md:mx-16 bg-black md:h-80 h-auto flex justify-around w-auto">
      <div
        className="  text-white w-full flex md:flex-row flex-col bg-cover bg-center bg-no-repeat py-7 counterBG"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="py-10 noBlur md:py-0 w-auto md:h-auto md:w-1/4  flex flex-col justify-center items-center gap-2">
          <div
            ref={elemRef}
            className="text-5xl font-helv"
            style={{ color: "#EFAE00" }}
          >
            ∞
          </div>
          <div className="text-2xl md:mt-3 font-helv text-center">Concepts</div>
        </div>
        <div className="py-10 md:py-0 h-1/3 w-auto md:h-auto md:w-1/4  flex flex-col justify-center items-center gap-2">
          <div
            ref={elemRef}
            className="text-5xl font-helv"
            style={{ color: "#EFAE00" }}
          >
            {isVisible && <CountUp start={0} end={250} duration={5} />}
          </div>
          <div className="text-2xl font-helv w-full md:mt-3 text-center">
            Completed Projects
          </div>
        </div>
        <div className="py-10 md:py-0 h-1/3 w-auto md:h-auto md:w-1/4  flex flex-col justify-center items-center gap-2">
          <div
            ref={elemRef}
            className="text-5xl font-helv"
            style={{ color: "#EFAE00" }}
          >
            {isVisible && <CountUp start={0} end={245} duration={5} />}
          </div>
          <div className="text-2xl font-helv md:mt-3 text-center">
            Satisfied Customers
          </div>
        </div>
        <div className="py-10 md:py-0 h-1/3 w-auto md:h-auto md:w-1/4  flex flex-col justify-center items-center gap-2">
          <div
            ref={elemRef}
            className="text-5xl font-helv"
            style={{ color: "#EFAE00" }}
          >
            {isVisible && <CountUp start={0} end={15} duration={5} />}+
          </div>
          <div className="text-2xl font-helv md:mt-3 text-center">
            Associated Brands
          </div>
        </div>
      </div>
    </div>
  );
}
