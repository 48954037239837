import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export default function Homepage() {
  const homeCarousel = [
    {
      name: "aliant",
      src: "https://dm2306files.storage.live.com/y4m7qeOKvtGho_-NRmRuqfYRbNGFGk5SMurdLFniKcXwl7UY_fOXwaIkI6kK32yDK9_cljsE9bI9kz3fgZbFZPf8-2akOOr8kiSbLjbWknSugT9eBRa7VNOsk0TRrzLcdqPAHx1EmBjwmz4GXxyiYCebWfMo4uH5rGoLXYIxEcyeWtXYfo8JXVoyfwtYkkS3MgV?width=1600&height=1600&cropmode=none",
    },
    {
      name: "infinity1",
      src: "https://dm2306files.storage.live.com/y4mUc2NKW2K28ccZH5oOavyZQi48DHYUixa0D6RLoD-_3i5yOT_yf6I-tzSQOQ67w7ZHUHzuWysORqp-jaqBoArRlElroJElgyiYxcQoe9dzzkzK5SpGn1PnTi0oR8N6igT5mQlFOGPremcQIkcqf1OSBno4sruyADRquxnlL2qqTL0bLTr8Y6xCHUMCgwpZBZF?width=3543&height=2024&cropmode=none",
    },
    {
      name: "infinity2",
      src: "https://dm2306files.storage.live.com/y4mfoAlqdGhqoRlhJZ1izxtmXjCBHOYbRx33Sk1FNMIwRu-4Jg5Dqo5qn5VfFdAkMShZzV3FSa8dyOoSc0bSD7KxbGhMdyVlS2La5l6P_fzbRNli5jhRY4CvvRu4tPl8zgUQ1MjmChOrgD8Ie59u9u9WRES3HnwVhJLPyO_mum2RIcMCiaq3jErRGIa0keqNUHn?width=3543&height=2024&cropmode=none",
    },
    {
      name: "metropolis",
      src: "https://dm2306files.storage.live.com/y4medwJgeayRKNL3huGXgNEmP9uqOGnKrDRTZae9VdQBO_TICRZibX76PH8OjLI2lXqm-ZXriwrhld-_5dX3qmrVmzKTLdh1f7E7mMtGZsztJWZ92uCbCKX-NIcQc9dP5i6lwBisx2QOXHJ7PC54A_mpuH9gKE_9X1Jjz4lgKJiSPZwD-fvFDQ4F-vDhkY2P9Fh?width=3543&height=2024&cropmode=none",
    },
    {
      name: "natural",
      src: "https://dm2306files.storage.live.com/y4mMLMIKCkkBrR9hKIeXZtNVTfYcJq39yhxg5m2_K0pXcq5mFO5F1vY7YQZUsN2Y3FoFgoVGUEJSFnKh8Md75_J-iBPPGXdbSJO-q06YenfrS07-RxDCYOWSbVqmklyBVJMyxpYLdkswj6xCv3hNk9o1mBGWPIJp3J-4AOZLY49HlPrFjxk4git_f90X9HruYbm?width=1600&height=1600&cropmode=none",
    },
    {
      name: "infity3",
      src: "https://dm2306files.storage.live.com/y4m0anmuS50vuSHdjYFfHhnx_JaCDbnYPCJq05Ku9SlwYn6eUzvukLOiSID4PUBVNYsibl5GTgcKpIZqKNmAteyc-iaAJPwSzeMzvaujyakgxMyqf1AZ3TRA9l0GFoIWDuP9QAu4g_7cB3qjz3OWG1JF4sKah4mXeddc3pKUmW3Bpu2zcpi2AfhWTuXcjcc9c9x?width=3543&height=2024&cropmode=none",
    },
  ];
  const advantageFeatures = [
    {
      name: "Elegance",
      image: "Elegance.png",
      description:
        "Mirius is a Latin word that stands for Elegant and Luxurious, we stand by our name in everything that we do.",
    },
    {
      name: "Symmetry",
      image: "Symmetry.png",
      description:
        " We believe that without symmetry your flow of thoughts cannot be converted.",
    },
    {
      name: "Aesthetic",
      image: "Aesthetic.png",
      description:
        "Every space that we create has an aesthetic value added to it.",
    },
  ];

  return (
    <div id="home">
      <Carousel
        showThumbs={false}
        showIndicators={false}
        showArrows={false}
        swipeable={false}
        autoPlay={true}
        infiniteLoop={true}
      >
        {homeCarousel.map((input, index) => (
          <div
            key={index}
            className="homeImage md:mt-16 min-w-max bg-cover bg-no-repeat bg-center "
            style={{ backgroundImage: `url('${input.src}')` }}
          />
        ))}
      </Carousel>
      <div className="flex md:flex-row flex-col w-full justify-between px-5 md:px-16">
        <div className="md:w-5/12 md:my-10 my-5 md:pr-8">
          <div className="flex md:w-full flex-col p-8 bg-mir-medlightgrey h-full ">
            <h1 className="font-helvLight text-5xl md:w-1/2 w-full mb-8">
              The Mirius Advantage
            </h1>
            <div className="border-b-1 border-black" />
            <p className="mt-7 md:text-9r font-helvLight text-lg">
              Mirius Interni believes in sustainable living and strives at
              giving attention to detail. Customization is our key and we cater
              to individual needs with individual solutions.
            </p>
          </div>
        </div>
        <div className="md:w-7/12 items-center justify-between md:items-start w-full md:my-10 my-2 md:flex-row flex-col  flex">
          {advantageFeatures.map((input, index) => (
            <div className="md:w-1/3 flex items-center justify-center flex-col md:p-3 md:mb-0 mb-3  h-full ">
              <img
                // src={"/images/" + input.image + ".png"}
                src={`/images/${input.image}`}
                // className="h-1/2 md:w-3/5 w-2/5"
                width="100px"
                height="100px"
                alt={input.name}
              />
              <h1 className="mt-5 text-3xl md:text-2xl font-helvLight font-normal md:mb-5 mb-3">
                {input.name}
              </h1>
              <p className=" font-helvLight md:text-9r  text-lg md:mb-0 mb-5">
                {input.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
