import React, { useState, useEffect } from "react";
import API from "./services/API";
import axios from "axios";

export default function Brands() {
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const { data: brands } = await axios.get(API.brands);
    setBrands(brands);
  };

  // brand nav
  const brandNav = ["all", "kitchen", "wardrobe", "living spaces"];
  const [selectedBrand, setSelectedBrand] = useState("all");

  return (
    <div className="flex flex-col px-16 pt-32 md:pt-20  w-screen ">
      {/* header */}
      <div className="flex flex-col   justify-center md:w-5/6">
        <h1 className=" text-4xl font-semibold  ">BRANDS</h1>
        <p className=" my-6  ">
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using ‘Content here, content here’, making it
          look like readable English.
        </p>
      </div>
      {/* brand nav */}
      <div className="flex flex-col md:flex-row  uppercase ">
        {brandNav.map((item, index) => (
          <div
            key={index}
            onClick={() => setSelectedBrand(item)}
            value={item}
            className={`"text-xl mr-5 cursor-pointer p-1  border-b-2   hover:border-yellow-400 " ${
              item === selectedBrand
                ? "border-yellow-300"
                : " border-transparent"
            } `}
          >
            {item}
          </div>
        ))}
      </div>
      {/* brand display */}
      <div className="flex flex-wrap  w-full h-full ">
        {brands &&
          brands
            .filter((brand) => brand.tag.includes(selectedBrand))
            .map((brand, index) => (
              <a
                href={`/brands/${brand.name}`}
                className={`flex flex-col w-full  md:w-3/10 h-full my-6 cursor-pointer  ${
                  (index + 1) % 3 != 0 ? "md:mr-16" : " "
                }  `}
              >
                <div
                  key={index}
                  className={`bg-gray-200   w-full h-60 bg-cover bg-no-repeat bg-center `}
                  style={{
                    backgroundImage: `url('${brand.image}')`,
                  }}
                />

                <div className="my-3 flex  ">
                  <div className="font-bold">{brand.name} </div>
                  <div className="mx-2"> {" - " + brand.tagline}</div>
                </div>
              </a>
            ))}
      </div>
    </div>
  );
}
