import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import API from "./services/API";
import axios from "axios";
import Caretip from "./Caretip";
import { Listbox } from "@headlessui/react";
// filter icon from react icons
import { BsFilter } from "react-icons/bs";

export default function Brands(props) {
  const history = useHistory();

  const category = props.match.params.category;
  const [themeData, setThemeData] = useState([]);
  const [categoryDescription, setCategoryDescription] = useState();
  const [subCategoryHeader, setSubCategoryHeader] = useState("");

  const [selectedTheme, setSelectedTheme] = useState("all");
  const [selectedSubCat, setSelectedSubCat] = useState("all");
  const [themeNav, setThemeNav] = useState(["all"]);
  const [subCategoryNav, setSubCategoryNav] = useState(["all"]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    // theme info
    const { data: themeData } = await axios.get(API.productThemes);
    setThemeData(
      themeData.filter(
        (data) => data.category.toLowerCase() === category.toLowerCase()
      )
    );

    // description
    const { data: categoryData } = await axios.get(API.products);
    // redirecting to product page when product don't exist
    if (
      categoryData.filter(
        (data) => data.name.toLowerCase() === category.toLowerCase()
      ).length == 0
    ) {
      history.push("/products");
      return;
    }

    setCategoryDescription(
      categoryData.filter(
        (data) => data.name.toLowerCase() === category.toLowerCase()
      )[0].description
    );
    setSubCategoryHeader(
      categoryData.filter(
        (data) => data.name.toLowerCase() === category.toLowerCase()
      )[0].subCategoryHeaders
    );
    // adding themeNav values

    if (
      categoryData.filter(
        (data) => data.name.toLowerCase() === category.toLowerCase()
      )[0].themes !== null
    ) {
      let themeNav = categoryData
        .filter((data) => data.name.toLowerCase() === category.toLowerCase())[0]
        .themes.split(",");
      themeNav.unshift("all");
      setThemeNav(themeNav);
      setSelectedTheme(themeNav[0]);
    }

    // adding subCategoryNav values
    if (
      categoryData.filter(
        (data) => data.name.toLowerCase() === category.toLowerCase()
      )[0].subCategories !== null
    ) {
      let subCategoryNav = categoryData
        .filter((data) => data.name.toLowerCase() === category.toLowerCase())[0]
        .subCategories.split(",");
      subCategoryNav.unshift("all");
      setSubCategoryNav(subCategoryNav);
    }
  };
  const card = (theme, index) => {
    // for products that donot have specific product page
    const hoverEffectFlag = theme.tags == null || theme.tags.includes("indian");
    return (
      <a
        href={!hoverEffectFlag && `/products/${category}/${theme.theme}`}
        key={index}
        className={`   relative flex flex-col bg-black my-9  md:my-9 h-48 md:h-60 product-theme-parent  w-full product-theme-parent  md:w-3/10      
        }  `}
      >
        <div
          className={`    absolute left-0 top-0 w-full h-full  md:h-60 bg-cover bg-no-repeat bg-center ${
            !hoverEffectFlag && "product-theme-thumbnail"
          }  `}
          style={{
            backgroundImage: `url('${theme.thumbnailImage}')`,
          }}
        ></div>
        {!hoverEffectFlag && (
          <div className="product-theme-overlay absolute h-full md:h-60 w-full top-0 left-0 text-white    flex  flex-col  justify-center items-center px-10 text-center ">
            <div className="  font-poppins tracking-wider text-2xl font-bold my-2 uppercase">
              {theme.theme}{" "}
            </div>
            <div className="my-2  leading-tight font-helvThin tracking-wide">
              {theme.tagline}
            </div>
            <a
              className="underline  my-2"
              href={`/products/${category}/${theme.theme}`}
            >
              Discover
            </a>
          </div>
        )}
        <div className="absolute -bottom-9 text-2xl font-helvLight">
          {theme.theme}
        </div>
      </a>
    );
  };
  return (
    <>
      <div className="flex flex-col px-5 md:px-16 pt-32 md:pt-20  w-full h-full ">
        {/* path */}
        <div className="flex my-3 md:my-8 font-helvRegular font-semibold tracking-widest">
          <a className="mr-1" href="/products">
            Products{" "}
          </a>
          <div className="mx-1">
            {`> ${category
              .split(" ")
              .map((word) => word[0].toUpperCase() + word.slice(1) + " ")
              .join(" ")}`}{" "}
          </div>
        </div>

        {/* header */}
        <div className="flex flex-col justify-center w-full text-justify md:w-5/6">
          <h1 className=" text-4xl font-helvLight text-left  ">
            {category
              .split(" ")
              .map((word) => word[0].toUpperCase() + word.slice(1) + " ")
              .join(" ")}{" "}
          </h1>
          <p className=" my-6 text-sm md:text-base font-helvRegular font-semibold tracking-wider">
            {categoryDescription && categoryDescription.split("\n")[0]}
            <br />
            <br />
            {categoryDescription && categoryDescription.split("\n")[1]}
          </p>
        </div>

        {/* theme nav */}
        {themeNav && themeNav.length > 1 && (
          <div className="flex flex-col md:flex-row font-helvThin font-semibold tracking-widest  uppercase md:items-center">
            {themeNav &&
              themeNav.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setSelectedTheme(item);
                    setSelectedSubCat("all");
                  }}
                  value={item}
                  className={`"text-xl mr-5 cursor-pointer p-1 border-b-2 hover:border-yellow-400   " ${
                    item === selectedTheme
                      ? "border-yellow-300"
                      : " border-transparent"
                  } `}
                >
                  {item}
                </div>
              ))}
          </div>
        )}

        {/* subcategory dropdown */}
        {selectedTheme !== "all" && subCategoryHeader.includes(selectedTheme) && (
          <div className="font-helvLight max-w-max text-sm my-1">
            <div className="absolute z-20 ">
              <Listbox value={selectedSubCat} onChange={setSelectedSubCat}>
                <Listbox.Button className="flex items-center ">
                  <BsFilter className="text-gray-600" />
                  <div className="uppercase mx-1 ">{selectedSubCat} </div>
                </Listbox.Button>
                <Listbox.Options className=" rounded-sm border-1 py-1  border-gray-600 bg-white ">
                  {subCategoryNav.map((item, index) => (
                    <Listbox.Option
                      className="uppercase cursor-pointer  px-2 py-1  hover:bg-gray-200 "
                      key={index}
                      value={item}
                    >
                      {item}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Listbox>
            </div>
          </div>
        )}
        {/* products display */}
        <div className="flex  flex-wrap justify-between  w-full h-full ">
          {selectedTheme === "all" && themeData.length > 0 ? (
            themeData && themeData.map((theme, index) => card(theme, index))
          ) : themeData &&
            themeData.filter(
              (data) =>
                (data.tags.split(",").includes(selectedTheme) &&
                  data.subcategory === selectedSubCat.toLowerCase()) ||
                (data.tags.split(",").includes(selectedTheme) &&
                  selectedSubCat === "all")
            ).length > 0 ? (
            themeData
              .filter(
                (data) =>
                  (data.tags.split(",").includes(selectedTheme) &&
                    data.subcategory === selectedSubCat.toLowerCase()) ||
                  (data.tags.split(",").includes(selectedTheme) &&
                    selectedSubCat === "all")
              )
              .map((theme, index) => card(theme, index))
          ) : (
            // error message
            <div className="flex justify-center item-center h-40vh items-center text-center text-sm md:text-base w-full">
              No Data Available for this product! Check out other{" "}
              <span>
                <a href="/products" className="underline italic mx-1">
                  products
                </a>
              </span>
              .
            </div>
          )}
        </div>

        {/* care tips */}
        <Caretip />

        {/* virtual store tour */}

        {selectedTheme && selectedTheme.toLowerCase() == "italian" && (
          <>
            <div class="  md:text-2xl text-base  font-helvLight text-left tracking-wider   mt-10 uppercase">
              Experience your new Stosa kitchen virtually.
            </div>
            <div className="w-full h-full relative my-2">
              <iframe
                src="https://my.matterport.com/show/?m=Zzdt2VCMLXF&help=null&hl=0&nt=0&play=1&qs=1&ts=-1&brand=1&dh=1&gt=1&hr=1&mls=0&mt=1&f=1&lang=en&nozoom=0&wh=1&guides=1&kb=1&lp=0&mf=1&title=2&tourcta=1&vr=1&vrcoll=0"
                allowFullScreen
                className="bg-gray-500 w-full  z-10 h-60vh md:h-70vh justify-end flex items-end bg-cover bg-center bg-no-repeat mb-2 "
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
