import React from "react";
// icons
import { FaFacebookF, FaInstagram, FaPhone } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";

const LOGO = "/images/Mirius_LOGO.png";
export default function Footer() {
  const sitemap = [
    {
      name: "",
      pages: [
        {
          name: "Kitchen",
          url: "/products/kitchen",
        },
        {
          name: "Wardrobes",
          url: "/products/wardrobes",
        },
        {
          name: "Living Spaces",
          url: "/products/Modular%20Living%20Spaces",
        },
        {
          name: "Office Space",
          url: "/products/Modular%20Office%20Spaces",
        },
      ],
    },
    {
      name: "",
      pages: [
        {
          name: "Products",
          url: "/products",
        },
        {
          name: "About Us",
          url: "/about",
        },
        {
          name: "Contact",
          url: "/contact",
        },
      ],
    },
  ];

  return (
    <div className="w-full h-full px-5 md:px-16 mb-10">
      <div className="flex-col flex w-full h-full px-5 md:px-0  bg-mir-medlightgrey">
        <div className="flex flex-col md:flex-row items-center justify-between     md:px-16 md:pt-10   ">
          {/* logo and address */}
          <div className="flex flex-col md:w-2/6 justify-center   w-full h-full  py-4 md:py-0">
            <div
              className="bg-white h-15vh   md:h-20vh w-full bg-cover bg-center bg-no-repeat my-2 md:my-0 "
              style={{ backgroundImage: `url('${LOGO}')` }}
            />
            <div className="  text-xs md:mt-8 w-full text-justify  my-1 md:my-0.5">
              <span className="font-semibold">Address : </span>
              <span className=" font-helvLight tracking-wide  ">
                Mirius Interni LLP #770, Guruprasad Vasavi Classic 10th Main
                Road, Jayanagar 4th Block Next to IDBI Bank Bangalore - 560011
              </span>
            </div>
            <div className="text-sm font-helvLight  flex items-center my-0.5">
              <FaPhone className="  text-black " />{" "}
              <a href="tel:9886125540 " className="mx-1">
                9886125540{" "}
              </a>
              {" / "}
              <a href="tel:9880998808 " className="mx-1">
                9880998808{" "}
              </a>
            </div>
            <div class="text-sm font-helvLight  flex items-center">
              <AiOutlineMail className="mr-1 text-black" />
              <a href="mailto:director@miriusinterni.com">
                director@miriusinterni.com
              </a>
            </div>
          </div>
          {/* sitemap */}
          <div className="flex flex-col w-full md:w-1/4 md:mr-16">
            <div className=" flex flex-row md:flex-wrap justify-between w-full  md:mb-4 md:h-20vh  ">
              {sitemap.map((page, index) => (
                <div
                  className={`w-1/2 md:w-auto flex flex-col    md:text-left my-5 md:my-0 ${index % 2 === 0 ? "text-left" : "text-right"
                    }`}
                  key={index}
                >
                  {/* <div className="font-bold mb-2 md:mb-4">{page.name}</div> */}
                  <div className="flex flex-col">
                    {page.pages.map((item, index) => (
                      <div key={index}>
                        <a
                          href={item.url}
                          className="text-brand-gray my-0.5 md:my-1.5  text-sm md:text-base font-helvLight  "
                        >
                          {item.name}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            {/* contact icons */}
            <div className="flex md:full items-center  justify-between my-2 md:my-0">
              <a href="tel:9886125540 ">
                <FaPhone className="p-2 md:p-1.5 text-4xl md:text-3xl  bg-black text-white rounded-full" />
              </a>
              <a href="mailto:director@miriusinterni.com">
                <AiOutlineMail className="p-2 md:p-1.5 text-4xl md:text-3xl  bg-black text-white rounded-full" />
              </a>
              <a href="https://www.facebook.com/miriusinterni/">
                <FaFacebookF className="p-2 md:p-1.5 text-4xl md:text-3xl bg-black text-white  rounded-full" />
              </a>
              <a href="https://www.instagram.com/stosacucineblr/">
                <FaInstagram className="p-2 md:p-1.5 text-4xl md:text-3xl bg-black text-white  rounded-full" />
              </a>
            </div>
          </div>
        </div>
        {/* copyrights */}
        <div className="flex w-full justify-center text-center text-sm my-2 ">
          &copy; Copyright 2021 - Mirius Interni LLP
        </div>
      </div>
    </div>
  );
}
